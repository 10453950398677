<template>
  <div class="flex flex-col">
    <div class="flex flex-col">
      <div class="w-full flex flex-col space-y-2">
        <div class="w-full flex flex-col">
          <label for="">Format</label>
          <select
            v-model="data.type"
            class="mb-2 border rounded px-2 py-1 text-xs focus:outline-none bg-transparent"
          >
            <option v-for="(type, index) in types" :key="`option-${index}`" :value="type">
              {{ type }}
            </option>
          </select>
        </div>

        <div class="w-full flex items-center">
          <label for="">Status</label>
          <toggle v-model="data.active" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'

export default {
  name: 'new-notification-settings',
  props: ['payload'],
  components: {},
  data() {
    return {
      data: {},
      types: ['Email', 'SMS'],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = { ...this.payload }
    },
    save() {
      axios
        .patch(
          `/organisation/setting/notification/${this.data.id}/`,
          { active: this.data.active, type: this.data.type },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(() => {
          this.$emit('close-modal', { show: false })
          this.$bus.$emit('reload-notification-settings-data-table')
        })
    },
  },
  watch: {},
}
</script>
